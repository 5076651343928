import {Orchestrator} from '@amzn/f3-excelsior-orchestrator-api';
import {Box, ColumnLayout, SpaceBetween} from '@amzn/awsui-components-react';
import React from 'react';
import {ValueWithLabel} from '../common/value-with-label';

export function translateDurationToString(duration?: Orchestrator.Types.Duration): string {
    if (!duration || !(duration.hour || duration.minute || duration.second)) {
        return 'N/A';
    }

    let message = '';
    if (duration.hour) {
        message += duration.hour + (duration.hour > 1 ? ' hours ' : ' hour ');
    }

    if (duration.minute) {
        message += duration.minute + (duration.minute > 1 ? ' minutes ' : ' minute ');
    }

    if (duration.second) {
        message += duration.second + (duration.second > 1 ? ' seconds ' : ' second ');
    }

    return message;
}

export function ViewScheduleEvent(props: {event: Orchestrator.Types.ScheduleEvent | Orchestrator.Types.ConcreteEvent}) {
    const sla = translateDurationToString(props.event.sla);

    const genericSettings = (
        <SpaceBetween size="l" direction="vertical">
            <ValueWithLabel label="Sla">{sla}</ValueWithLabel>
        </SpaceBetween>
    );

    let eventSettings = (
        <SpaceBetween size="l" direction="vertical">
            <Box variant="div">
                <Box variant="strong">Unknown Event</Box>
            </Box>
        </SpaceBetween>
    );

    if (props.event.generator) {
        eventSettings = (
            <SpaceBetween size="l" direction="vertical">
                <Box variant="div">
                    <Box variant="strong">Generate Event</Box>
                </Box>
                <ValueWithLabel label="Calculation Start Time">{props.event.generator.startTimeCalculation}</ValueWithLabel>
                <ValueWithLabel label="Forecast Type">{props.event.generator.forecastType}</ValueWithLabel>
                <ValueWithLabel label="Override Business ID">
                    {props.event.generator.overrideBusinessId ? props.event.generator.overrideBusinessId : 'null'}
                </ValueWithLabel>
                <ValueWithLabel label="Override Country">
                    {props.event.generator.overrideCountry ? props.event.generator.overrideCountry : 'null'}
                </ValueWithLabel>
                <ValueWithLabel label="Override Flow">
                    {props.event.generator.overrideFlow ? props.event.generator.overrideFlow : 'null'}
                </ValueWithLabel>
                <ValueWithLabel label="Model Group Name">
                    {props.event.generator.modelGroupName ? props.event.generator.modelGroupName : 'null'}
                </ValueWithLabel>
                <ValueWithLabel label="Model Api Version">
                    {props.event.generator.modelApiVersion ? props.event.generator.modelApiVersion : 'null'}
                </ValueWithLabel>
            </SpaceBetween>
        );
    }

    if (props.event.publish) {
        eventSettings = (
            <SpaceBetween size="l" direction="vertical">
                <Box variant="div">
                    <Box variant="strong">Publish Event</Box>
                </Box>
                <ValueWithLabel label="Method">{props.event.publish.method}</ValueWithLabel>
                <ValueWithLabel label="Forecast Type">{props.event.publish.forecastType}</ValueWithLabel>
                <ValueWithLabel label="Override Business ID">
                    {props.event.publish.overrideBusinessId ? props.event.publish.overrideBusinessId : 'null'}
                </ValueWithLabel>
                <ValueWithLabel label="Override Country">
                    {props.event.publish.overrideCountry ? props.event.publish.overrideCountry : 'null'}
                </ValueWithLabel>
                <ValueWithLabel label="Override Flow">
                    {props.event.publish.overrideFlow ? props.event.publish.overrideFlow : 'null'}
                </ValueWithLabel>
            </SpaceBetween>
        );
    }

    if (props.event.promote) {
        eventSettings = (
            <SpaceBetween size="l" direction="vertical">
                <Box variant="div">
                    <Box variant="strong">Promote Event</Box>
                </Box>
            </SpaceBetween>
        );
    }

    if (props.event.ingestion) {
        eventSettings = (
            <SpaceBetween size="l" direction="vertical">
                <Box variant="div">
                    <Box variant="strong">Ingestion Event</Box>
                </Box>
                <ValueWithLabel label="Data">{props.event.ingestion.data}</ValueWithLabel>
            </SpaceBetween>
        );
    }

    return (
        <ColumnLayout columns={2} variant="text-grid">
            {eventSettings}
            {genericSettings}
        </ColumnLayout>
    );
}
