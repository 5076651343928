import React, {useState} from 'react';
import {Alert, AlertProps, Box, Button, ColumnLayout, Header} from '@amzn/awsui-components-react';
import {ConfirmationModal} from './confirmation-modal';
import '../../assets/styles/file-download-upload.scss';
import {TemplateDropzone} from './template-dropzone';
import {DownloadTemplateButton} from './download-template-button';
import {Notification} from '../navigation/page-layout';
import {BusinessId, Country, Flow, ServiceResource, useAuth} from '../hooks/use-auth';
import {
    useAddEmailIdentity,
    useDeleteEmailIdentity,
    useGetEmailIdentity,
    useListBulkOverrideTemplateForTags,
    useResetOverrides,
} from '../hooks/use-forecast-store-api';
import {translateErrorToReactNode} from '../common';
import {ButtonWithConfirmation} from '../common/button-with-confirmation';

interface BulkOverrideProps {
    showResetOverrideButton: boolean;
    pushNotification: (notification: Notification) => void;
    resetOverridesAlertStay: boolean;
    bulkOverrideTemplateTag: string[];
    additionalTemplateDefinitions: TemplateDefinition[];
    contentClassName: string;
    hideNote?: boolean;
}

interface TemplateDefinition {
    templateId: string;
    downloadButton: string;
    portalDescription: string;
    useBulkOverrideV2: boolean;
}

const MARKETING_EVENT_TEMPLATE_ID = 'marketing_event_tune';
const MARKETING_EVENT_TEMPLATE_DOWNLOAD_BUTTON = 'Marketing Template';
const MARKETING_EVENT_PORTAL_DESCRIPTION = 'MARKETING';
const CPT_OFFSETS_TEMPLATE_ID = 'cpt_offsets';
const CPT_OFFSETS_TEMPLATE_DOWNLOAD_BUTTON = 'CPT Offsets Template';
const CPT_OFFSETS_PORTAL_DESCRIPTION = 'CPT OFFSETS';

/**
 * Internal bulk override page backed by Bulk Override Automation APIs (v2 and legacy)
 *
 * Visible for testing
 */
export function BulkOverride(props: BulkOverrideProps) {
    const auth = useAuth();

    const forecastStoreViewClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreView);
    const forecastStoreEditClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreEdit);

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [alert, setAlert] = useState({
        visible: false,
        type: 'success' as AlertProps.Type,
        header: undefined as React.ReactNode | undefined,
        action: undefined as React.ReactNode | undefined,
        message: '',
    });
    const [emailAlertVisible, setEmailAlertVisible] = useState(true);

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const {execute: executeResetOverrides} = useResetOverrides(
        forecastStoreViewClientConfiguration,
        forecastStoreEditClientConfiguration,
        {
            businessId: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
        },
        createErrorListener('ResetOverrides failed'),
        [auth]
    );

    const {value: listBulkOverrideTemplateResponse} = useListBulkOverrideTemplateForTags(
        forecastStoreEditClientConfiguration,
        {
            businessId: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
            bulkOverrideTemplateTags: props.bulkOverrideTemplateTag,
        },
        true,
        createErrorListener('ListBulkOverrideTemplate failed'),
        [auth]
    );

    const {value: getEmailIdentityResponse} = useGetEmailIdentity(
        forecastStoreEditClientConfiguration,
        createErrorListener('GetEmailIdentity failed'),
        [auth]
    );

    const {execute: executeAddEmailIdentity} = useAddEmailIdentity(
        forecastStoreEditClientConfiguration,
        createErrorListener('AddEmailIdentity failed'),
        [auth]
    );

    const {execute: executeDeleteEmailIdentity} = useDeleteEmailIdentity(
        forecastStoreEditClientConfiguration,
        createErrorListener('DeleteEmailIdentity failed'),
        [auth]
    );

    function createOnChange(header: string) {
        return (message: string, type: AlertProps.Type, action?: React.ReactNode) => {
            setAlert({
                visible: true,
                type: type,
                header: header,
                action: action,
                message: message,
            });
        };
    }

    const onChangeResetOverrides = createOnChange('Reset Overrides Status');
    const resetOverrides = async () => {
        setConfirmationModalVisible(false);
        try {
            onChangeResetOverrides('Resetting the overrides is currently in process. See Forecast History tab for status.', 'info');

            await executeResetOverrides();

            if (!props.resetOverridesAlertStay) {
                setTimeout(() => {
                    setAlert((prevState) => ({
                        ...prevState,
                        visible: false,
                    }));
                }, 20000);
            }
        } catch (error) {
            onChangeResetOverrides('There is a service error. Please contact someone from the support team.', 'error');
        }
    };

    const addEmail = async () => {
        await executeAddEmailIdentity();
    };

    const deleteEmail = async () => {
        await executeDeleteEmailIdentity();
    };

    const templateDefinitions = (listBulkOverrideTemplateResponse || []).map((template) => ({
        templateId: template.templateId,
        downloadButton: template.downloadButton,
        portalDescription: template.portalDescription,
        useBulkOverrideV2: true,
    }));

    const allTemplateDefinitions = props.additionalTemplateDefinitions.concat(templateDefinitions);

    const allTemplateDefinitionsByName = Array.from(
        allTemplateDefinitions
            .reduce(
                (entryMap, e) => entryMap.set(e.portalDescription, [...(entryMap.get(e.portalDescription) || []), e]),
                new Map<string, TemplateDefinition[]>()
            )
            .entries()
    );

    const resetOverrideButton = props.showResetOverrideButton ? (
        <React.Fragment>
            <div className="overrides-header">
                <Box float="right">
                    <Button
                        variant={'primary'}
                        data-testid="reset-overrides-button"
                        onClick={() => setConfirmationModalVisible(true)}
                    >
                        Reset All Overrides
                    </Button>
                </Box>
            </div>
            <ConfirmationModal
                visible={confirmationModalVisible}
                promptHeader={'Reset All Overrides'}
                prompt={'Are you sure you want to delete all overrides for this forecast?'}
                confirmPhrase={'delete'}
                onCancel={() => setConfirmationModalVisible(false)}
                onConfirm={resetOverrides}
            />
        </React.Fragment>
    ) : (
        <React.Fragment />
    );

    return (
        <React.Fragment>
            <Alert
                data-testid="email-alert"
                dismissible
                visible={emailAlertVisible}
                action={
                    <ButtonWithConfirmation
                        label={getEmailIdentityResponse?.verified ? 'Disable' : 'Enable'}
                        variant={'normal'}
                        disabled={false}
                        promptHeader={'Email Notification'}
                        prompt={
                            getEmailIdentityResponse?.verified
                                ? 'Your email has been removed and you will no longer receive emails for your future interactions.'
                                : 'Your email has been added to receive notifications of your Excelsior interactions. ' +
                                  'You will shortly receive a verification email that will be sent from an AWS. ' +
                                  'Please click the link to finish the enabling for emails process.'
                        }
                        confirmPhrase={''}
                        onConfirm={() => {
                            setEmailAlertVisible(false);
                            getEmailIdentityResponse?.verified ? deleteEmail() : addEmail();
                        }}
                        showInput={false}
                        showCancel={false}
                    />
                }
                onDismiss={() => setEmailAlertVisible(false)}
                header={'Excelsior Emailing'}
            >
                Excelsior can now send emails with details of your interactions directly to the user upon the outcome. If you wish
                to change your email notification preference, please Enable/Disable by selecting the button.
            </Alert>
            <Alert
                onDismiss={() =>
                    setAlert((prevAlert) => ({
                        ...prevAlert,
                        visible: false,
                    }))
                }
                visible={alert.visible}
                dismissAriaLabel="Close alert"
                dismissible
                type={alert.type}
                header={alert.header}
                action={alert.action}
            >
                <div className={'error-message'}>{alert.message}</div>
            </Alert>
            {resetOverrideButton}
            <div className={props.contentClassName}>
                <ColumnLayout columns={allTemplateDefinitionsByName.length} disableGutters>
                    {allTemplateDefinitionsByName.map((entry) => {
                        return (
                            <div className="center" key={entry[0]}>
                                <div className="centered-header">
                                    <Header variant={'h1'}>{entry[0]}</Header>
                                </div>
                                <br />
                                {entry[1].map((template) => {
                                    return (
                                        <>
                                            <DownloadTemplateButton
                                                templateName={template.downloadButton}
                                                templateType={template.templateId}
                                                useBulkOverrideV2={template.useBulkOverrideV2}
                                                pushNotification={props.pushNotification}
                                            />
                                            <div className="uploadsContainer" style={{height: 500 / entry[1].length}}>
                                                <div className={'upload'}>
                                                    <TemplateDropzone
                                                        templateType={template.templateId}
                                                        useBulkOverrideV2={template.useBulkOverrideV2}
                                                        pushNotification={props.pushNotification}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        );
                    })}
                </ColumnLayout>
            </div>
            <div className={'note center'} hidden={props.hideNote ?? false}>
                <Alert statusIconAriaLabel="Info">Note: These uploads will take precedence over any previous overrides.</Alert>
            </div>
        </React.Fragment>
    );
}

export function Overrides(props: {pushNotification: (notification: Notification) => void}) {
    return (
        <BulkOverride
            showResetOverrideButton={true}
            pushNotification={props.pushNotification}
            bulkOverrideTemplateTag={['bulk_override', 'reset_override']}
            resetOverridesAlertStay={false}
            additionalTemplateDefinitions={[]}
            contentClassName={'content'}
        />
    );
}

export function InputSignal(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();

    const additionalTemplateDefinitions = getInputSignalAdditionalTemplateDefinitions(
        auth.authInformation!.current!.businessId,
        auth.authInformation!.current!.country,
        auth.authInformation!.current!.flow
    );

    return (
        <BulkOverride
            showResetOverrideButton={false}
            pushNotification={props.pushNotification}
            bulkOverrideTemplateTag={['input_signal']}
            resetOverridesAlertStay={false}
            additionalTemplateDefinitions={additionalTemplateDefinitions}
            contentClassName={'contentSignals'}
            hideNote={true}
        />
    );
}

/**
 * These are the legacy templates.
 */
function getInputSignalAdditionalTemplateDefinitions(businessId: string, country: string, flow: string) {
    const marketingTemplateDefinition: TemplateDefinition = {
        templateId: MARKETING_EVENT_TEMPLATE_ID,
        downloadButton: MARKETING_EVENT_TEMPLATE_DOWNLOAD_BUTTON,
        portalDescription: MARKETING_EVENT_PORTAL_DESCRIPTION,
        useBulkOverrideV2: false,
    };

    const cptOffsetsTemplateDefinition: TemplateDefinition = {
        templateId: CPT_OFFSETS_TEMPLATE_ID,
        downloadButton: CPT_OFFSETS_TEMPLATE_DOWNLOAD_BUTTON,
        portalDescription: CPT_OFFSETS_PORTAL_DESCRIPTION,
        useBulkOverrideV2: false,
    };

    const templateDefinitions: TemplateDefinition[] = [];

    if (businessId === BusinessId.WFM) {
        templateDefinitions.push(marketingTemplateDefinition);
    }

    if (
        (businessId === BusinessId.UFF && country === Country.US && flow === Flow.OUTBOUND) ||
        (businessId === BusinessId._3P && country === Country.US && flow === Flow.OUTBOUND) ||
        (businessId === BusinessId._3P && country === Country.JP && flow === Flow.OUTBOUND)
    ) {
        templateDefinitions.push(marketingTemplateDefinition);
    }

    const isCptOffsetsTemplateEnabledForCountry = country === Country.US || country === Country.DE;
    if (businessId === BusinessId.UFF && isCptOffsetsTemplateEnabledForCountry && flow === Flow.OUTBOUND) {
        templateDefinitions.push(cptOffsetsTemplateDefinition);
    }

    return templateDefinitions;
}
