import React from 'react';
import {Box, Icon, ColumnLayout, SpaceBetween, Popover, Button} from '@amzn/awsui-components-react';
import {DashboardEvent} from '@amzn/f3-excelsior-orchestrator-api/clients/orchestrator';
import {ValueWithLabel} from '../common/value-with-label';
import {translateDurationToString} from '../orchestrator/view-schedule-event';

interface EventPopoverProps {
    event: DashboardEvent;
}

enum DashboardEventType {
    Generate,
    Publish,
    Promote,
    Ingest,
    PromoteAndPublish,
}

export const getDashEventType = (event: DashboardEvent) => {
    if (event.generator) {
        return DashboardEventType[DashboardEventType.Generate];
    }
    if (event.publish) {
        return DashboardEventType[DashboardEventType.Publish];
    }
    if (event.promote) {
        return DashboardEventType[DashboardEventType.Promote];
    }
    if (event.ingestion) {
        return DashboardEventType[DashboardEventType.Ingest];
    }
    if (event.promoteAndPublish) {
        return DashboardEventType[DashboardEventType.PromoteAndPublish];
    }
    return 'Unknown';
};

export default function EventPopover(props: EventPopoverProps) {
    const getEventColorByStatus = (event: DashboardEvent) => {
        if (event.status == 'succeed') {
            return 'text-status-info'; // blue
        }
        if (event.status == 'error') {
            return 'text-status-error'; // red
        }
        if (event.status == 'running') {
            return 'text-status-success'; // green
        }
        if (event.status == 'not_started') {
            return 'text-status-inactive'; // gray
        }
        return 'text-status-inactive';
    };

    const signForSLAMet = (event: DashboardEvent) => {
        return (
            <>
                <Button variant="link" wrapText={false}>
                    <Box color={getEventColorByStatus(props.event)} fontWeight="heavy" fontSize="body-m">
                        {!event.isSlaMet ? <Icon name="status-warning" size="inherit" variant="warning" /> : <></>}
                        {`${event.scheduledTime.toLocaleTimeString()}${event.adhoc ? ' Adhoc ' : ' '}${getDashEventType(event)}`}
                    </Box>
                </Button>
            </>
        );
    };

    const generateEventSubtypePopoverContent = (event: DashboardEvent) => {
        if (event.generator) {
            return (
                <>
                    <div>
                        <Box variant="strong">Generate Event</Box>
                    </div>
                    <ValueWithLabel label="Calculation Start Time">{event.generator.startTimeCalculation}</ValueWithLabel>
                    <ValueWithLabel label="Forecast Type">{event.generator.forecastType}</ValueWithLabel>
                    <ValueWithLabel label="Override Business ID">
                        {event.generator.overrideBusinessId ? event.generator.overrideBusinessId : 'N/A'}
                    </ValueWithLabel>
                    <ValueWithLabel label="Override Country">
                        {event.generator.overrideCountry ? event.generator.overrideCountry : 'N/A'}
                    </ValueWithLabel>
                    <ValueWithLabel label="Override Flow">
                        {event.generator.overrideFlow ? event.generator.overrideFlow : 'N/A'}
                    </ValueWithLabel>
                    <ValueWithLabel label="Model Group Name">
                        {event.generator.modelGroupName ? event.generator.modelGroupName : 'N/A'}
                    </ValueWithLabel>
                    <ValueWithLabel label="Model Api Version">
                        {event.generator.modelApiVersion ? event.generator.modelApiVersion : 'N/A'}
                    </ValueWithLabel>
                </>
            );
        }
        if (event.publish) {
            return (
                <>
                    <div>
                        <Box variant="strong">Publish Event</Box>
                    </div>
                    <ValueWithLabel label="Method">{event.publish.method}</ValueWithLabel>
                    <ValueWithLabel label="Forecast Type">{event.publish.forecastType}</ValueWithLabel>
                    <ValueWithLabel label="Override Business ID">
                        {event.publish.overrideBusinessId ? event.publish.overrideBusinessId : 'N/A'}
                    </ValueWithLabel>
                    <ValueWithLabel label="Override Country">
                        {event.publish.overrideCountry ? event.publish.overrideCountry : 'N/A'}
                    </ValueWithLabel>
                    <ValueWithLabel label="Override Flow">
                        {event.publish.overrideFlow ? event.publish.overrideFlow : 'N/A'}
                    </ValueWithLabel>
                </>
            );
        }
        if (event.ingestion) {
            return (
                <>
                    <div>
                        <Box variant="strong">Ingest Event</Box>
                    </div>
                    <ValueWithLabel label="Data">{event.ingestion.data}</ValueWithLabel>
                </>
            );
        }
        if (event.promote) {
            return (
                <>
                    <div>
                        <Box variant="strong">Promote Event</Box>
                    </div>
                </>
            );
        }
        return <></>;
    };

    const generatePopoverContent = (event: DashboardEvent) => {
        return (
            <ColumnLayout columns={2} variant="text-grid">
                <SpaceBetween size="l">
                    <div>
                        <Box variant="awsui-key-label">Scheduled Time</Box>
                        <div>{event.scheduledTime.toLocaleString()}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Original Scheduled Time</Box>
                        <div>{event.originalScheduledTime.toLocaleString()}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Start Time</Box>
                        <div>{event.startTime ? event.startTime.toLocaleString() : 'N/A'}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">End Time</Box>
                        <div>{event.endTime ? event.endTime.toLocaleString() : 'N/A'}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Status</Box>
                        <div>{event.status}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Is SLA Met</Box>
                        <div>{event.isSlaMet ? 'true' : 'false'}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">SLA</Box>
                        <div>{translateDurationToString(event.sla)}</div>
                    </div>
                </SpaceBetween>
                <SpaceBetween size="l">{generateEventSubtypePopoverContent(event)}</SpaceBetween>
            </ColumnLayout>
        );
    };

    return (
        <Box>
            <Popover
                dismissAriaLabel="Close"
                fixedWidth
                header={getDashEventType(props.event)}
                content={generatePopoverContent(props.event)}
                size="large"
                triggerType="custom"
            >
                {signForSLAMet(props.event)}
            </Popover>
        </Box>
    );
}
